<template>
    <div v-if="tags.length > 0" class="flex flex-wrap gap-2">
        <span class="block leading-[12px] px-[8px] py-[2px] border-[1px] text-2xs border-[#1570EF] text-[#1570EF] rounded-[12px] uppercase truncate" v-for="tag in tags" :key="tag">
            {{ tag }}
        </span>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: String,
            default: ""
        }
    },
    computed: {
        tags() {
            if (!this.data || this.data.trim() === "") return [];
            
            return this.data.split(',').map(tag => tag.trim());
        }
    }
}
</script>
